var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Detail Prestasi ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"student_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Siswa","label-for":"student_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"student_id","reduce":function (student_id) { return student_id.id; },"placeholder":"Pilih Siswa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listSiswa,"label":"name","disabled":""},model:{value:(_vm.dataStudent.student_id),callback:function ($$v) {_vm.$set(_vm.dataStudent, "student_id", $$v)},expression:"dataStudent.student_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal","label-for":"date"}},[_c('validation-provider',{attrs:{"name":"Tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal","type":"date","disabled":""},model:{value:(_vm.dataStudent.date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "date", $$v)},expression:"dataStudent.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Event","label-for":"event"}},[_c('validation-provider',{attrs:{"name":"Nama Event","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"event","state":errors.length > 0 ? false : null,"placeholder":"Nama Event","disabled":""},model:{value:(_vm.dataStudent.event),callback:function ($$v) {_vm.$set(_vm.dataStudent, "event", $$v)},expression:"dataStudent.event"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kategori","label-for":"category","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"category","reduce":function (category) { return category.id; },"placeholder":"Pilih Kategori","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPerformance,"label":"performance_type","disabled":""},model:{value:(_vm.dataStudent.category),callback:function ($$v) {_vm.$set(_vm.dataStudent, "category", $$v)},expression:"dataStudent.category"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Prestasi","label-for":"achivement"}},[_c('validation-provider',{attrs:{"name":"achivement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"achivement","state":errors.length > 0 ? false : null,"placeholder":"Prestasi","disabled":""},model:{value:(_vm.dataStudent.achivement),callback:function ($$v) {_vm.$set(_vm.dataStudent, "achivement", $$v)},expression:"dataStudent.achivement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }